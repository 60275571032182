<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col v-if="!loading">
        <h3>Tables</h3>
        <div v-for="table in tables" :key="table">
          <b-button variant="link" @click="showTable(table)">{{ table }}</b-button>
        </div>
      </b-col>
      <b-col v-if="columns.length > 0 && !loadingColumns">
        <h3>Columns for {{ table }}</h3>
        <div v-for="column in columns" :key="column">
          {{ column }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'tabless',
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Links"
    if (!ac.can(this.user.acgroups).readAny('table').granted) {
      this.$router.push({ name: 'Start' })
    }
    this.$stat.log({ page: 'admin tables', action: 'open page' })
    this.load()
  },
  data () {
    return {
      columns: [],
      loading: false,
      loadingColumns: false,
      table: '',
      tables: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.tables = await this.$Amplify.API.get('cosmos', '/tables')
        this.loading = false
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    showTable: async function (table) {
      try {
        this.loadingColumns = true
        this.table = table
        this.columns = await this.$Amplify.API.get('cosmos', `/table/${table}/columns`)
        this.loadingColumns = false
      } catch (e) {
        this.$logger.warn('showing table error' + e)
      }
    }
  }
}
</script>

<style>
</style>
